:root {
    --button-bg: rgba(25, 126, 173, 0.4); 
    --button-bg-hover: rgba(25, 126, 173, 0.8); 
    --button-border-color:rgba(43, 133, 190, 0.83); 
    --button-neon-glow: #50a3da 0px 0px 20px;
    --button-danger-bg: rgba(125, 20, 20, 0.4);
    --button-danger-bg-hover: rgba(175, 5, 5, 0.514); 
    --button-danger-border-color:rgb(255, 61, 61);  
    --button-danger-glow:  #ae0808 0px 0px 20px;
    --button-success-bg: rgba(44, 131, 40, 0.616);
    --button-success-bg-hover: rgba(22, 188, 33, 0.672); 
    --button-success-border-color:rgb(35, 234, 55);  
    --button-success-glow:  #085e0b 0px 0px 20px;
    --button-warning-bg: rgba(242, 172, 20, 0.503);
    --button-warning-bg-hover: rgba(238, 183, 44, 0.799); 
    --button-warning-border-color:rgb(234, 171, 35);  
    --button-warning-glow:  #cea317 0px 0px 20px;
    --button-shadow-sm: #010b10 0px 2px 2px;
}

.btn {
        border-radius: 3px 10px 3px 10px;
        box-shadow: var(--button-shadow-sm);
        transition: all .3s;
}

.btn-info {
    background-color: var(--button-bg);
    border: 1px solid var(--alt-border-color);
    color: #fff;
    
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: var(--button-bg-hover);
    box-shadow: var(--button-neon-glow);
  }

.btn-danger {
    background-color: var(--button-danger-bg);
    border: 1px solid var(--button-danger-border-color);
    color: #fff;
}

  
.btn-danger:hover {
    color: #fff;
    background-color: var(--button-danger-bg-hover);
    box-shadow: var(--button-danger-glow);
    border: 1px solid var(--button-danger-border-color);
  }
  
.btn-success {
    background-color: var(--button-success-bg);
    border: 1px solid var(--button-success-border-color);
    color: #fff;
}

  
.btn-success:hover {
    color: #fff;
    background-color: var(--button-success-bg-hover);
    box-shadow: var(--button-success-glow);
    border: 1px solid var(--button-success-border-color);
  }

  .btn-warning {
    background-color: var(--button-warning-bg);
    border: 1px solid var(--button-warning-border-color);
    color: #fff;
}

  
.btn-warning:hover {
    color: #fff;
    background-color: var(--button-warning-bg-hover);
    box-shadow: var(--button-warning-glow);
    border: 1px solid var(--button-warning-border-color);
  }