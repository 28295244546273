:root {
  --main-bg-color:  rgba(7, 36, 71, 0.1);
  --main-border-color: rgba(62, 76, 85, 0.5); 
  --alt-border-color:rgba(43, 133, 190, 0.83); 
  --text-neon: #65bcf6;
  --text-neon-glow: #275371 0px 0px 2px;
  --text-warm: #ecb45a;
  --text-warm-glow: #7d6a38 0px 0px 6px;
  --shadow-sm: #010b10 0px 2px 2px;
}


body {
  background-color: #090f13;
  background-image: url('/assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
}

.grid {
  background-image: url('/assets/honey-grid.png');
  background-repeat: repeat;
  background-size: 300px;
}

h3, h1, h2, h5, h4, h6, p, a {
  color: var(--text-neon);
}

h1, h3 {
  color: var(--text-warm);
  font-weight: lighter;
  text-transform: uppercase;
  text-shadow: var(--text-warm-glow);
}


.glass {
  background-color: var(--main-bg-color);
  border-bottom: 1px solid var(--main-border-color);
}

.frosty {
  backdrop-filter: blur(3px);
}