:root{
--card-bg-color: rgba(7, 36, 71, 0.1); 
--card-border-color: rgba(62, 76, 85, 0.5); 
}

.card {
    background-color: var(--card-bg-color);  
    border-color: var(--card-border-color);
    border-radius: 3px 30px 3px 30px;
    overflow: hidden;
  }
