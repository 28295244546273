body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;   
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --main-bg-color:  rgba(7, 36, 71, 0.1);
  --main-border-color: rgba(62, 76, 85, 0.5); 
  --alt-border-color:rgba(43, 133, 190, 0.83); 
  --text-neon: #65bcf6;
  --text-neon-glow: #275371 0px 0px 2px;
  --text-warm: #ecb45a;
  --text-warm-glow: #7d6a38 0px 0px 6px;
  --shadow-sm: #010b10 0px 2px 2px;
}


body {
  background-color: #090f13;
  background-image: url('/assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
}

.grid {
  background-image: url('/assets/honey-grid.png');
  background-repeat: repeat;
  background-size: 300px;
}

h3, h1, h2, h5, h4, h6, p, a {
  color: #65bcf6;
  color: var(--text-neon);
}

h1, h3 {
  color: #ecb45a;
  color: var(--text-warm);
  font-weight: lighter;
  text-transform: uppercase;
  text-shadow: #7d6a38 0px 0px 6px;
  text-shadow: var(--text-warm-glow);
}


.glass {
  background-color: rgba(7, 36, 71, 0.1);
  background-color: var(--main-bg-color);
  border-bottom: 1px solid rgba(62, 76, 85, 0.5);
  border-bottom: 1px solid var(--main-border-color);
}

.frosty {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
:root{
--card-bg-color: rgba(7, 36, 71, 0.1); 
--card-border-color: rgba(62, 76, 85, 0.5); 
}

.card {
    background-color: rgba(7, 36, 71, 0.1);
    background-color: var(--card-bg-color);  
    border-color: rgba(62, 76, 85, 0.5);  
    border-color: var(--card-border-color);
    border-radius: 3px 30px 3px 30px;
    overflow: hidden;
  }

:root {
    --button-bg: rgba(25, 126, 173, 0.4); 
    --button-bg-hover: rgba(25, 126, 173, 0.8); 
    --button-border-color:rgba(43, 133, 190, 0.83); 
    --button-neon-glow: #50a3da 0px 0px 20px;
    --button-danger-bg: rgba(125, 20, 20, 0.4);
    --button-danger-bg-hover: rgba(175, 5, 5, 0.514); 
    --button-danger-border-color:rgb(255, 61, 61);  
    --button-danger-glow:  #ae0808 0px 0px 20px;
    --button-success-bg: rgba(44, 131, 40, 0.616);
    --button-success-bg-hover: rgba(22, 188, 33, 0.672); 
    --button-success-border-color:rgb(35, 234, 55);  
    --button-success-glow:  #085e0b 0px 0px 20px;
    --button-warning-bg: rgba(242, 172, 20, 0.503);
    --button-warning-bg-hover: rgba(238, 183, 44, 0.799); 
    --button-warning-border-color:rgb(234, 171, 35);  
    --button-warning-glow:  #cea317 0px 0px 20px;
    --button-shadow-sm: #010b10 0px 2px 2px;
}

.btn {
        border-radius: 3px 10px 3px 10px;
        box-shadow: #010b10 0px 2px 2px;
        box-shadow: var(--button-shadow-sm);
        transition: all .3s;
}

.btn-info {
    background-color: rgba(25, 126, 173, 0.4);
    background-color: var(--button-bg);
    border: 1px solid var(--alt-border-color);
    color: #fff;
    
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: rgba(25, 126, 173, 0.8);
    background-color: var(--button-bg-hover);
    box-shadow: #50a3da 0px 0px 20px;
    box-shadow: var(--button-neon-glow);
  }

.btn-danger {
    background-color: rgba(125, 20, 20, 0.4);
    background-color: var(--button-danger-bg);
    border: 1px solid rgb(255, 61, 61);
    border: 1px solid var(--button-danger-border-color);
    color: #fff;
}

  
.btn-danger:hover {
    color: #fff;
    background-color: rgba(175, 5, 5, 0.514);
    background-color: var(--button-danger-bg-hover);
    box-shadow: #ae0808 0px 0px 20px;
    box-shadow: var(--button-danger-glow);
    border: 1px solid rgb(255, 61, 61);
    border: 1px solid var(--button-danger-border-color);
  }
  
.btn-success {
    background-color: rgba(44, 131, 40, 0.616);
    background-color: var(--button-success-bg);
    border: 1px solid rgb(35, 234, 55);
    border: 1px solid var(--button-success-border-color);
    color: #fff;
}

  
.btn-success:hover {
    color: #fff;
    background-color: rgba(22, 188, 33, 0.672);
    background-color: var(--button-success-bg-hover);
    box-shadow: #085e0b 0px 0px 20px;
    box-shadow: var(--button-success-glow);
    border: 1px solid rgb(35, 234, 55);
    border: 1px solid var(--button-success-border-color);
  }

  .btn-warning {
    background-color: rgba(242, 172, 20, 0.503);
    background-color: var(--button-warning-bg);
    border: 1px solid rgb(234, 171, 35);
    border: 1px solid var(--button-warning-border-color);
    color: #fff;
}

  
.btn-warning:hover {
    color: #fff;
    background-color: rgba(238, 183, 44, 0.799);
    background-color: var(--button-warning-bg-hover);
    box-shadow: #cea317 0px 0px 20px;
    box-shadow: var(--button-warning-glow);
    border: 1px solid rgb(234, 171, 35);
    border: 1px solid var(--button-warning-border-color);
  }
.footer {
    min-height: 500px;
    background-color: rgba(0,0,0,0.2);
}
